<template>
  <div>
    <div class="card-toolbar mb-5">
      <!--        <router-link v-if="$can('general_credits.create')"  to="/sales/general-credits/create" class="btn btn-primary font-weight-bolder">-->
      <!--            <v-icon>mdi-plus</v-icon>{{ $t('credits.add_sales_credits_general')}}-->
      <!--        </router-link>-->
      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div v-if="showAdvancedSearch" class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('credits.customer') }}</label>
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :multiple="false"
                  :options="customers"
                  :placeholder="$t('credits.customer')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="fullname"
                  track-by="id"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="credit_code">{{ $t('credits.credit_code') }}</label>
              <input id="credit_code" v-model="filters.credit_code" class="form-control" type="text">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('credits.from_date') }}</label>
              <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('credits.to_date') }}</label>
              <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('credits.currency') }}</label>
              <select id="currency_id" v-model="filters.currency_id" class="custom-select" name="">
                <option v-for="row in currencies" :key="row.id" :value="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('credits.added_by') }}</label>
              <multiselect
                  v-model="user"
                  :multiple="false"
                  :options="users"
                  :placeholder="$t('credits.added_by')"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :taggable="false"
                  label="name"
                  track-by="id"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_quantity">{{ $t('credits.from_quantity') }}</label>
              <input id="from_quantity" v-model="filters.from_quantity" class="form-control" type="number">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_quantity">{{ $t('credits.to_quantity') }}</label>
              <input id="to_quantity" v-model="filters.to_quantity" class="form-control" type="number">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select id="f_status" v-model="filters.status" class="custom-select" name="" type="text">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :key="index" :value="row.id">{{ row.title }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                <span><i class="fas fa-search"></i>
                  <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button id="m_reset" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                <span><i class="fas fa-trash-restore"></i>
                  <span>{{ $t('reset_search') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom">
      <div class="card-body">
        <!-- <div>

        </div> -->
        <!-- End Filter -->
        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
          <template slot="#" slot-scope="props">
            <span>{{ props.index }}</span>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('general_credits.change_status')" class="btn btn-secondary m-btn m-btn--icon w-auto"
                    @click="changeStatus(props.row.id, props.row.status)">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>

            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">

            <!--               -->
            <v-btn v-if="$can('general_credits.update')" v-b-tooltip.hover :title="$t('edit')" :to="`/sales/general-credits/edit/${props.row.id}`" color="pink"
                   icon>
              <v-icon class="mr-2 text-info" small>mdi-pencil</v-icon>
            </v-btn>
            <!--               -->
            <v-icon v-if="$can('general_credits.delete') && props.row.status != 2" v-b-tooltip.hover :title="$t('delete')" class="text-danger"
                    small @click="deleteItem(props.row)">
              mdi-delete
            </v-icon>

            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " class="m-2"
                        variant="outline-primary">
              <router-link v-if="$can('general_credits.payment_sales_invoices') && props.row.status == 2"
                           :to="`/sales/payment_sales_invoices/create/${props.row.id}/credits`"
                           class="dropdown-item">
                {{ $t('MENU.payment_sales_credits') }}
              </router-link>

              <div v-if="$can('general_credits.pdf_print_tax_invoice')"
                   class="dropdown-item d-flex justify-content-between">
                <label>{{ $t('tax_invoice_printing') }}</label>
                <div class="d-flex justify-content-between">
                  <router-link v-b-tooltip.hover :title="$t('print')" :to="`/print/general-credits-tax-invoice/${props.row.id}/print`" class="ml-3 mr-3"
                               target="_blank">
                    <i class="fas fa-print"></i>
                  </router-link>
                  <router-link v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-credits-tax-invoice/${props.row.id}/pdf`" class="ml-3 mr-3"
                               target="_blank">
                    <i class="fas fa-file-pdf"></i>
                  </router-link>
                </div>
              </div>
              <!--                  <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_credits.printing_export_pdf')">-->
              <!--                    <label>{{ $t('MENU.invoice_printing_80_mm') }}</label>-->
              <!--                    <div class="d-flex justify-content-between">-->
              <!--                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-credit/${props.row.id}/print`">-->
              <!--                        <i class="fas fa-print"></i>-->
              <!--                      </router-link>-->
              <!--                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-credit/${props.row.id}/pdf`">-->
              <!--                        <i class="fas fa-file-pdf"></i>-->
              <!--                      </router-link>-->
              <!--                    </div>-->
              <!--                  </div>-->


              <!--                  -->
              <!--                    <router-link v-if="$can('general_credits.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales-credit/${props.row.id}/print`">-->
              <!--                        {{ $t('MENU.invoice_printing') }}-->
              <!--                    </router-link>-->
              <!--&lt;!&ndash;                    <router-link v-if="$can('general_credits.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales-credit/${props.row.id}/pdf`">&ndash;&gt;-->
              <!--&lt;!&ndash;                        {{ $t('export_invoice_pdf') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->

              <!--                  <router-link target="_blank" v-if="$can('general_credits.pdf_print_tax_invoice')" class="dropdown-item" :to="`/print/general-credits-tax-invoice/${props.row.id}/print`">-->
              <!--                    {{ $t('tax_invoice_printing') }}-->
              <!--                  </router-link>-->
              <!--&lt;!&ndash;                  <router-link target="_blank" v-if="$can('general_credits.pdf_print_tax_invoice')" class="dropdown-item" :to="`/print/general-credits-tax-invoice/${props.row.id}/pdf`">&ndash;&gt;-->
              <!--&lt;!&ndash;                    {{ $t('export_tax_invoice_pdf') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->

              <!--                  <router-link target="_blank" v-if="$can('general_credits.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/general-credits-tax-invoice/${props.row.id}/print/simplified`">-->
              <!--                    {{ $t('tax_invoice_printing_simplified') }}-->
              <!--                  </router-link>-->
              <!--&lt;!&ndash;                  <router-link target="_blank" v-if="$can('general_credits.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/general-credits-tax-invoice/${props.row.id}/pdf/simplified`">&ndash;&gt;-->
              <!--&lt;!&ndash;                    {{ $t('export_tax_invoice_pdf_simplified') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                  </router-link>&ndash;&gt;-->


            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" :title="$t('change_status')" hide-footer size="md">
      <change-status-form :current-id="innerId"
                          :current-status="statusId"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          @hide-modal="hideModal()"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";


export default {
  name: "index-credits",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/credits',
      routeChangeStatus: 'sales/credit/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: null,
        credit_code: null,
        from_date: null,
        to_date: null,
        currency_id: null,
        user_id: null,
        status: null,
        from_quantity: null,
        to_quantity: null,
        is_general: 1,
      },
      status_list: [],

      // status_list: [
      //     {id: 1, text: this.$t('active')},
      //     {id: 0, text: this.$t('inactive')},
      // ],
      columns: ['#', 'customer_name', 'create_date', 'credit_code', 'total', 'total_paid_amount', 'currency_name', 'is_paid_status_name', 'invoice_code', 'status', 'actions'],
      data: [],
      currencies: [],
      customers: [],
      users: [],
      innerId: null,
      statusId: null,
      customer: null,
      user: null,

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('credits.customer'),
          create_date: that.$t('credits.create_date'),
          credit_code: that.$t('credits.credit_code'),
          total: that.$t('credits.total'),
          total_paid_amount: that.$t('paid_amount'),
          currency_name: that.$t('credits.currency'),
          is_paid_status_name: that.$t('sales_invoices.payment_status'),
          invoice_code: that.$t('invoice_code'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales_credits_management")}]);
    this.getCurrencies();
    // this.getCustomers();
    // this.getUsers();
    this.getStatus();
  },
  methods: {
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.credit_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.filters.from_quantity = null;
      this.filters.to_quantity = null;
      this.user = null;
      this.customer = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'credits'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>
